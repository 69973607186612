<template>
  <div class="sellerfaq">
    <!-- 购买流程 -->
    <ul class="shopStep">
      <li v-for="(shopItem, index) in stepList" :key="index">
        <div class="shopSvg">
          <svg
            width="50px"
            height="50px"
            viewBox="0 0 16 16"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              :d="pathItem.pathD"
              v-for="(pathItem, index) in shopItem.path"
              :key="index"
            ></path>
          </svg>
        </div>
        <div class="shopText">{{shopItem.name}}</div>
        <div class="shopImg" v-if="shopItem.bool">
          <img src="../../assets/img/step.png" alt="" />
        </div>
      </li>
    </ul>
    <!-- 常见问题 -->
    <div class="container">
      <h4>Frequently Asked Questions</h4>
      <el-collapse v-model="activeName" accordion>
        <el-collapse-item :title="conItem.title" :name="conItem.name" v-for="(conItem, index) in containerList" :key="index">
          <div>
            <p v-for="(pItem, index) in conItem.text" :key="index">{{pItem.textP}}</p>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeName: '1',
      // 流程列表
      stepList: [
        {
          bool: true,
          name: 'Product payment release',
          path: [
            {
              pathD:
                'M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z'
            },
            {
              pathD:
                'M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z'
            }
          ]
        },
        {
          bool: true,
          name: 'seller creates product information',
          path: [
            {
              pathD:
                'M4 1h5v1H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V6h1v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2z'
            },
            { pathD: 'M9 4.5V1l5 5h-3.5A1.5 1.5 0 0 1 9 4.5z' },
            {
              pathD:
                'M5 11.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z'
            }
          ]
        },
        {
          bool: true,
          name: 'Buyer submits request',
          path: [
            {
              pathD:
                'M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z'
            }
          ]
        },
        {
          bool: true,
          name: 'seller to review',
          path: [
            {
              pathD:
                'M5.443 1.991a60.17 60.17 0 0 0-2.725.802.454.454 0 0 0-.315.366C1.87 7.056 3.1 9.9 4.567 11.773c.736.94 1.533 1.636 2.197 2.093.333.228.626.394.857.5.116.053.21.089.282.11A.73.73 0 0 0 8 14.5c.007-.001.038-.005.097-.023.072-.022.166-.058.282-.111.23-.106.525-.272.857-.5a10.197 10.197 0 0 0 2.197-2.093C12.9 9.9 14.13 7.056 13.597 3.159a.454.454 0 0 0-.315-.366c-.626-.2-1.682-.526-2.725-.802C9.491 1.71 8.51 1.5 8 1.5c-.51 0-1.49.21-2.557.491zm-.256-.966C6.23.749 7.337.5 8 .5c.662 0 1.77.249 2.813.525a61.09 61.09 0 0 1 2.772.815c.528.168.926.623 1.003 1.184.573 4.197-.756 7.307-2.367 9.365a11.191 11.191 0 0 1-2.418 2.3 6.942 6.942 0 0 1-1.007.586c-.27.124-.558.225-.796.225s-.526-.101-.796-.225a6.908 6.908 0 0 1-1.007-.586 11.192 11.192 0 0 1-2.417-2.3C2.167 10.331.839 7.221 1.412 3.024A1.454 1.454 0 0 1 2.415 1.84a61.11 61.11 0 0 1 2.772-.815z'
            },
            {
              pathD:
                'M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z'
            }
          ]
        },
        {
          bool: true,
          name: 'Buyer arrival evaluation',
          path: [
            {
              pathD:
                'M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z'
            }
          ]
        },
        {
          bool: false,
          name: 'seller refund',
          path: [
            {
              pathD:
                'M14 3H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z'
            },
            {
              pathD:
                'M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1zM1 9h14v2H1V9z'
            }
          ]
        }
      ],
      // 问题列表
      containerList: [
        {
          name: 1,
          title: '1.How does the crediscounts seller central work?',
          text: [
            { textP: 'Step 1: Create a crediscounts seller account and prepare relevant product information.' },
            { textP: 'Step 2: Submit a deal, and publish it on crediscounts for purchase.' },
            { textP: 'Step 3: After buyers apply your item, you can check the Requests on your seller central and approved them.' },
            { textP: 'Step 4: Buyers get your approval and upload their order info after purchase.' },
            { textP: 'Step 5: Confirm your orders and issue a refund to buyers accordingly.' }
          ]
        },
        {
          name: 2,
          title: '2.Are there any restrictions on how I can offer my product？',
          text: [
            { textP: 'Currently, there is no minimum or maximum on the number of products you need to offer within your campaigns. However, our minimum discount accepted is 30% off. Any products less than this discount will not be approved.' }
          ]
        },
        {
          name: 3,
          title: '3.Which Amazon market does crediscounts support?',
          text: [
            { textP: 'Support US, UK, DE, FR, IT, CA, JP, ES, BR, IN, MX. The United States site has the most traffic, followed by Germany, the United Kingdom, Spain, and other European countries.' }
          ]
        },
        {
          name: 4,
          title: '4.Will buyers leave a product review after purchase?',
          text: [
            { textP: '1.crediscounts won’t force buyers to leave reviews. But, we have the task feature which can guide buyers to leave reviews to earn points in order to buy more products.' },
            { textP: '2.To get reviews, you can offer great customer services to make buyers satisfied so that they voluntarily give positive feedback.Sellers can also contact buyers for further discussion.' }
          ]
        },
        {
          name: 5,
          title: '5.What are the promotion channels of crediscounts?',
          text: [
            { textP: 'crediscounts’s promotion platform covers the current mainstream social media, such as Facebook, Twitter, LinkedIn, Instagram, YouTube, Reddit, Quora, etc. At crediscounts, you will be able to find the same Amazon product offers as you do, and can find people with the same interests!' }
          ]
        },
        {
          name: 6,
          title: '6.Will the order be generated after the successful release of the discounted product?',
          text: [
            { textP: 'Answer: After successfully releasing the discounted products, the platform will try its best to guide buyers to purchase the product from Amazon, but there is no guarantee that buyers will buy the product. The order is mainly decided by the buyer.' }
          ]
        },
        {
          name: 7,
          title: '7.How to set the discount code?',
          text: [
            { textP: 'Answer: the discount code is used to deal with the sellers in the amazon background generated. Sellers set discount code, please be sure to confirm the type of discount code and the appropriate rules for user.crediscounts platform only supports discount lower than 30% off, and only provide sellers promotional products display function, does not undertake any seller loss caused due to incorrect discount code.' }
          ]
        },
        {
          name: 8,
          title: '8.Whether you can publish discounted products on multiple sites at the same time?',
          text: [
            { textP: 'Answer: crediscounts supports the release of discount products in multiple sites at the same time. The product type and quantity of each site are not affected.' }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.sellerfaq {
  text-align: left;
  background: #f2f2f2;
  padding: 30px 15px 50px 15px;
  .shopStep {
    background: white;
    display: flex;
    justify-content: space-between;
    padding: 30px 10px;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .shopSvg {
        svg {
          color: #f93;
        }
      }
      .shopText {
        margin-left: 20px;
        font-size: 16px;
        line-height: 1.5;
      }
      .shopImg {
        margin-left: 10px;
      }
    }
  }
  .container {
   background: white;
   margin-top: 20px;
   h4 {
     font-size: 28px;
     padding: 26px 36px;
   }
  //  .el-collapse-item__header {
  //    padding-left: 36px;
  //  }
   .el-collapse {
     .el-collapse-item {
        padding-left: 36px;
        border-bottom: 1px solid #EBEEF5;
       /deep/ .el-collapse-item__header {
         border-bottom: 0px;
         position: relative;
         padding-left: 15px;
         font-size: 16px;
         font-weight: 600;
         /deep/ .el-collapse-item__arrow {
           position: absolute;
           left: 0;
           top: 18px;
          //  transform: translateY(-50%);
         }
       }
       /deep/ .el-collapse-item__wrap {
         border-bottom: 0px;
         padding-left: 20px;
       }
     }
   }
  }
}
</style>
